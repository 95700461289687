<template>
  <div class="flex flex-wrap my-2" v-if="isOutOfStock">
    <span class="text-xl text-grey-blue">
      Unfortunately this item is <span class="text-xl font-bold">out of stock...</span>
    </span>
    <p class="mb-2 text-sm">
      You can still purchase this item and it will be added to a backorder list to be dispatched as soon
      as it’s available or get notified by checking the box below:
    </p>
    <button @click="handleClick" class="py-1 px-2 border cursor-pointer" type="button"
            v-bind:class="{
      'border-grey-blue text-grey-blue hover:text-white hover:bg-grey-blue': !subscribed,
      'border-orange text-orange hover:text-white hover:bg-orange': subscribed }">
      <i class="fas fa-bell"></i>
      <span class="inline-block text-center font-bold uppercase leading-none px-2.5 py-0.5 cursor-pointer">{{ !subscribed ? 'NOTIFY ME' : 'UNSUBSCRIBE' }}</span>
      <i class="far" v-bind:class="{ 'fa-check-square': subscribed, 'fa-square': !subscribed }"></i>
    </button>
  </div>
</template>

<script>

export default {
  name: 'OutOfStockSubscription',
  props: {
    isSubscribed: Boolean,
    endPoint: String,
    isOutOfStock: Boolean
  },
  data(){
    return {
      subscribed: this.isSubscribed
    }
  },
  computed: {},
  methods: {
    handleClick() {
      $.ajax({
        url: this.endPoint,
        data: {
          action: this.subscribed ? 'unsubscribe' : 'subscribe'
        },
        type: 'POST',
        success: (data) => {
          this.subscribed = !this.subscribed;
          let text = !this.subscribed ? 'You have been unsubscribed.' : 'You will be notified when this product comes back in stock';
          this.$store.dispatch('notifications/showFlash', {
            type: this.subscribed ? 'success' : 'info',
            message: text
          })
        },
        error: () => {
          this.$store.dispatch('notifications/showFlash', {
            type: 'error',
            message: 'Error updating your preference. Please reload the page and try again.'
          })
        }
      })
    }
  }
}
</script>
