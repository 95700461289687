<template>
  <span
    v-if="amount && isAuth && !(this.hideLabelIfOutOfStock && renderedText==='No Stock')"
    class="inline-block text-white text-xs text-center font-bold uppercase leading-none px-2.5 py-0.5 cursor-pointer"
    :class="stockLevel.colour"
    @click="handleClick"
  >
    <span class="inline-block">{{ renderedText }}</span>
  </span>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'stock-level',
  props: {
    hideLabelIfOutOfStock: {
      type:Boolean,
      default:false
    },
    amount: {
      required: true,
      // { [level as string]: string; }
      validator: val => typeof val === 'object' || val === null
    }
  },
  data() {
    return {
      showInfoText: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuth']),
    stockLevel() {
      return this.$store.getters['stockLevel/stockLevel'](this.amount)
    },
    renderedText() {
      return this.showInfoText ? this.stockLevel.text : this.stockLevel.amount
    }
  },
  methods: {
    handleClick() {
      this.showInfoText = !this.showInfoText
    }
  }
}
</script>
